define("discourse/plugins/df-core/models/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @see app/assets/javascripts/discourse/models/nav_item.js
   * @link https://github.com/discourse/discourse/blob/v1.4.0.beta6/app/assets/javascripts/discourse/models/nav_item.js#L10
   */
  var _default = _exports.default = Discourse.NavItem.extend({
    filterMode: function () {
      return this.get('name');
    }.property('name'),
    href: function () {
      return this.get('href');
    }.property('href')
  });
});